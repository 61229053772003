import React from 'react';
import MediaPreviewer from '../../common/MediaPreviewer';
import { PdfViewer } from '../../common/PdfViewer'

class Previewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: props.type
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.type !== this.props.type) {
            this.setState({ type: newProps.type })
        }

        if (newProps.postStatus !== this.props.postStatus && newProps.postStatus === "created") {
            this.setState({ type: '' })
        }
    }

    render_view(url) {
        {
            let base64Url = url.includes('base64')
            if (this.state.type === "pdf") {
                if (url) {
                    /*  if (base64Url) {
                         return (
                             <embed src={`${url}`} width="100%" height="700px" type="application/pdf" ></embed>
                         ) 
                         
                     } else {
                            return (<embed src={`https://docs.google.com/gview?embedded=true&url=${url}`} width="100%" height="700px" type="application/pdf" /> )
                        
                     } */
                    return (
                        <>
                            <PdfViewer
                                pdf={url}
                            ></PdfViewer>
                        </>
                    )
                }
            } else {
                return (<MediaPreviewer type={this.state.type} url={url} />)
            }


        }
    }

    render() {
        const { url } = this.props;

        return (
            <React.Fragment>
                {this.render_view(url)}
            </React.Fragment>
        )
    }
}

export default Previewer;
