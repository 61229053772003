import { useState } from 'react';

import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import './PdfViewer.scss';
import SvgIcon from './svg-icon/svg-icon';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
   'pdfjs-dist/build/pdf.worker.min.mjs',
   import.meta.url,
).toString();

const options = {
   cMapUrl: '/cmaps/',
   standardFontDataUrl: '/standard_fonts/',
};

export function PdfViewer(props) {
   const [file, setFile] = useState(props.pdf);
   const [width, setWidth] = useState(630);
   const [numPages, setNumPages] = useState(1);
   const [pageNumber, setPageNumber] = useState(1);
   const [scale, setScale] = useState(1.0);

   function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
   }

   const load_prev = (pageNum) => {
      if (pageNum > 1) {
         setPageNumber(pageNum - 1)
      }
   }

   const load_next = (pageNum) => {
      if (pageNum < numPages) {
         setPageNumber(pageNum + 1)
      }
   }

   const decrease_scale = (pageNum) => {
      if (scale != 0) {
         setScale(scale - 0.1)
      }
   }

   const increase_scale = (pageNum) => {
      setScale(scale + 0.1)
   }

   return (
      <>
         <div className="PDFView">
            <div className="PDFView__container">
               <div className="PDFView__container__document" >
                  <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                     {/*  {Array.from(new Array(numPages), (_el, index) => ( */}
                     <Page
                        key={`page_${pageNumber}`}
                        pageNumber={pageNumber}
                        width={width}
                        scale={scale}
                     />
                     {/* ))} */}
                     <div class="page-controls">
                        <div class="page-controls-inner">
                           {numPages > 1 && <button type="button" onClick={() => load_prev(pageNumber)}>‹</button>}
                           <span>{pageNumber} of {numPages}</span>
                           {numPages > 1 && <button type="button" onClick={() => load_next(pageNumber)}>›</button>}
                           <button className='zoom-btn' type="button" onClick={() => decrease_scale(pageNumber)}>-</button>
                           <SvgIcon name='zoom-icom' viewbox="0 0 54 54" />
                           <button className='zoom-btn' type="button" onClick={() => increase_scale(pageNumber)}>+</button>
                        </div>
                     </div>
                  </Document>
               </div>
            </div>
         </div>
      </>
   );
}